import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Abstract from "./components/Abstract";
import APP_PAGES from "../../page-constants";
import AddCopresenters from "./components/AddCopresenters";

export default function AbstractCreationForm({
    abstractId = -1,
    editMode = false,
}){

    const [submittedAbstract, setSubmittedAbstract] = useState();
    const [isSubmittingPresenter, setIsSubmittingPresenter] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(abstract){
        if(editMode || abstract.department.allows_copresenters === false){
            viewCompletedAbstract(abstract);
            return;
        }
        setSubmittedAbstract(abstract);
    }

    function viewCompletedAbstract(abstract){
        navigate(`${APP_PAGES.ABSTRACT_DETAIL.redirect}/${abstract.id}`);
    }

    function handlePresentersChange(abstract, options){
        setSubmittedAbstract(abstract);
    }

    return (
        <>
        {!submittedAbstract && (
            <Abstract
                abstractId={abstractId}
                editMode={editMode}
                afterSubmit={handleSubmit}
            />
        )}

        {submittedAbstract && (
            <AddCopresenters
                onPresentersChange={handlePresentersChange}
                onSubmit={() => viewCompletedAbstract(submittedAbstract)}
                isSubmitting={isSubmittingPresenter}
                abstract={submittedAbstract}
            />
        )}
        </>
    )
}