import { Grid, Box, Heading, UnorderedList, ListItem, Text } from "@chakra-ui/react";
import { mentorsQuery } from "../../../api/queries";
import { useQuery } from "@tanstack/react-query";
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Select } from "@chakra-ui/react";
import { useState } from "react";


export default function MentorCard() {
    const { data: mentors } = useQuery(mentorsQuery)

    const [selectedMentors, setSelectedMentors] = useState("");

    const departments = [...new Set(mentors.map(mentor => mentor.department?.name).filter(Boolean))];

    if (!mentors) {
        return <div/>;
    }

    return(
        <Grid
        py={[4, null, null, 10]}
        bgColor="grey.100"
        alignItems="center"
        justifyItems="center"
        >
            <Heading
                as="h2"
                textAlign="center"
                fontSize={["1.1em", null, null, "1.6em"]}
            >
                SRC Mentors
            </Heading>
            
            <Text
				mt={2}
				fontSize={["0.9em", null, null, "1.2em"]}
				fontWeight="600"
			>
                            Select your department to see your department's mentors.
            </Text>
            <p>You can also connect with other departments' mentors as well.</p>
        <Box py={4}>
            <Select
                placeholder="Select department"
                onChange={(e) => setSelectedMentors(e.target.value)}
                value={selectedMentors}
                borderColor="grey.300"
            >
                <option value="all">All departments</option>
                
                {departments
                .filter(department => department !== "College")
                .map(department => (
                    <option key={department} value={department}>
                        {department}
                    </option>
                ))}
                <option value="Data Science">Data Science</option>
                <option value="College">Other</option>
            </Select>
            <UnorderedList mt="1em">
                    {mentors
                        .filter(mentor => selectedMentors === "all" || mentor.department?.name === selectedMentors || (selectedMentors === "Data Science" && mentor.data_science))
                        .map(mentor => {
                            return (
                                <ListItem key={mentor.id}>
                                    {mentor?.link ? (
                                        <u>
                                            <a href={mentor?.link}>
                                                {mentor.name}
                                                {" (" + (mentor.department?.name === "College" ? "Other" : mentor.department?.name ?? "") + ")" + (mentor.data_science ? " (Data Science)" : "")}
                                                <ExternalLinkIcon mx="2px" />
                                            </a>
                                        </u>
                                    ) : (
                                        <span>
                                            {mentor.name}
                                            {" (" + (mentor.department?.name === "College" ? "Other" : mentor.department?.name ?? "") + ")" + (mentor.data_science ? " (Data Science)" : "")}
                                        </span>
                                    )}
                                </ListItem>
                            );
                        })}
                </UnorderedList>
        </Box>
        </Grid>
    );
};